
import { defineComponent } from 'vue'
import { onIonViewDidEnter } from '@ionic/vue'
import { useOrganizationAreas, useDeviations } from '@dview/logic'
import { DeviationType } from '@dview/shared/models'
import DeviationsList from '../shared/deviations-list/DeviationsList.vue'
import NovoGlowHeader from '../shared/NovoGlowHeader.vue'
import DeviationsFilterButton from '../shared/deviations-filter/DeviationsFilterButton.vue'

export default defineComponent({
    components: {
        DeviationsList,
        NovoGlowHeader,
        DeviationsFilterButton
    },
    setup() {
        const { activeAreaSelected } = useOrganizationAreas()
        const { refresh: refreshDeviations } = useDeviations(DeviationType.Open)

        onIonViewDidEnter(() => {
            refreshDeviations()
        })

        return {
            activeAreaSelected,
        }
    }
})
